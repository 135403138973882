body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.new_hero_banner_wrapper video{
  /* position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  display: block; */
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  object-fit: cover;
}

header{
  /* position: absolute !important; */
  background: rgba(0, 0, 0, 0.5) !important;
  
}
header svg{
  filter: brightness(0) invert(1) !important;
}
header img{
  filter: brightness(0) invert(1) !important;
}
header p{ 
  color: #fff !important;
}
.slick-slider{
  /* height: 160px; */
  /* padding-bottom: 192px; */
}
.slick-track{
  display: flex !important;
  align-items: center;
}

.slick-slide {
  margin: 0 10px;
  

}
.slick-slide img{
  height: auto !important;
  max-width: 100% !important;
}
.slick-slide .slide{
  /* background: #fff; */
  display: flex;
  align-items: center;
  /* height: 160px; */
}
/* .slick-list {
  margin: 0 -10px;
} */

.section_white p{ color: #000 !important;}

.section_white .line{
  background-color: #000 !important;
}


.section_black p{ color: #fff !important;}

.section_black .line{
  background-color: #fff !important;
}

.mobileAppSection .slick-slide .slide{
  /* background: #c7c5c5; */
  display: flex;
  align-items: center;
  /* height: 160px; */
}

.mobileAppSection h2{
  color: #fff;
  width: 100%;
  text-align: center;
  width: 54%;
  /* display: inline-block; */
  margin: 0 auto;
  font-weight: 400;
  margin-top: 83px;
}
.new_process_wrapper{
  padding-top: 142px;
}
.new_process_wrapper .section{
  margin-bottom: 12rem;
}
.new_process_wrapper .section:last-child{
  margin-bottom: 0;
}
.new_process_wrapper .section:nth-child(2n+2){
  text-align: right;
}

.new_process_wrapper .section h2{
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 12px;
  color: #fff;
}
.new_process_wrapper .section p{
  opacity: 0.75;
  font-size: 16px;
  line-height: 36px;
  color: #fff;
}
.new_hero_banner_wrapper{
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
}
#trustedBySection{
  margin-top: 100vh;
}

@media only screen and (max-width:700px){
  .new_hero_banner_wrapper video{
    height: 250px;
  }
  .new_hero_banner_wrapper{
    height: 250px;
  }
  #trustedBySection{
    margin-top: 168px;
  }
}